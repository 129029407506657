// File containing all project descriptions; similar to the info page for the board members

import React from "react";

// component imports
import BookletReader from "../../components/BookletReader/BookletReader";
import FoodbankResources from "../../components/FoodbankResources";
import GalleryCarousel from "../../components/GalleryCarousel";
import MentalHealthResources from "../../components/MentalHealthResources";
import OtherFoodbankResources from "../../components/OtherFoodbankResources";
import OtherRegionsFoodbank from "../../components/OtherRegionsFoodbank";

// Sinai project image imports
import food1 from "../../assets/Projects/food1.jpeg";
import food2 from "../../assets/Projects/food2.jpg";
import food3 from "../../assets/Projects/food3.jpg";
import food4 from "../../assets/Projects/food4.jpg";
import food5 from "../../assets/Projects/food5.jpg";

import solar1 from "../../assets/Projects/solar1.jpeg";
import solar2 from "../../assets/Projects/solar2.jpeg";
import solar3 from "../../assets/Projects/solar3.jpeg";
import solar4 from "../../assets/Projects/solar4.jpeg";

import brick1 from "../../assets/Projects/brick1.jpeg";
import brick2 from "../../assets/Projects/brick2.jpeg";

import group from "../../assets/Projects/group.jpeg";
import training from "../../assets/Projects/training.jpeg";
import training2 from "../../assets/Projects/training2.jpeg";
import training3 from "../../assets/Projects/training3.jpeg";
import training4 from "../../assets/Projects/training4.jpeg";

// partners logo imports
import esdc from "../../assets/Logos/ESDC.png";
import inara from "../../assets/Logos/inara-logo.png";
import unhcr from "../../assets/Logos/UNHCRLogo2.png";

// booklet cover imports
import eiCover from "../../assets/Booklets/Emotional_Intellignce/cover.jpg";
import smCover from "../../assets/Booklets/Stress_Management/cover.jpg";
import daCover from "../../assets/Booklets/Depression_And_Anxiety/cover.jpg";
import cprCover from "../../assets/Booklets/Cpr_And_First_Aid_Booklet/cover.jpg";

// document imports
import EmotionalIntelligenceBooklet from "../../assets/Booklets/Emotional_Intellignce/Booklet.pdf";
import StressManagementBooklet from "../../assets/Booklets/Stress_Management/Booklet.pdf";
import DepressionAndAnxietyBooklet from "../../assets/Booklets/Depression_And_Anxiety/Booklet.pdf";
import CprAndFirstAidBooklet from "../../assets/Booklets/Cpr_And_First_Aid_Booklet/Booklet.pdf";

// lists of images that will be used in carousels
const foodImages = [food1, food2, food3, food4, food5];
const solarImages = [solar1, solar4, solar2, solar3];
const brickList = [brick1, brick2];
const firstAidList = [group, training, training2, training3, training4];

// map of each page, with the link being the key and the value being the site's contents
export const descriptions = {
  "Mental-Health-Project": {
    title: "Mental Health Project",
    bottom: (
      <>
        <p>
          During Sinai Foundation Canada's participation in the Canada Summer
          Job program for the year 2022, six university students worked together
          to develop an initiative surrounding mental health to promote mental
          health awareness and educational resources. The students from
          different fields worked together and benefited from this program as
          they advanced their research, problem-solving, collaboration, critical
          thinking and many more skills which will help them in their
          professional life and future careers.
          <br />
          <br />
          The project was developed with the aim to promote mental health
          awareness and educational resources. As part of this initiative, we
          developed different educational booklets involving stress management,
          emotional intelligence and anxiety and depression in order to dispense
          knowledge on what these topic areas include and how to navigate/cope
          if we are directly experiencing any of the related mental health
          issues. Furthermore, a section was added to our website for further
          resources and support from different organizations that individuals
          can access.
        </p>

        <h2 className="subheader mt-4 mb-4" style={{ textAlign: "center" }}>
          Check out our booklets!
        </h2>
        {/* Booklet reader requires the title, length, cover, and pdf to the booklet */}
        <BookletReader
          booklets={[
            {
              title: "Emotional Intelligence",
              pdf: EmotionalIntelligenceBooklet,
              cover: eiCover,
              length: 11,
              video: "https://youtu.be/QHzZKpj7Y6o",
            },
            {
              title: "Stress Management",
              cover: smCover,
              pdf: StressManagementBooklet,
              length: 12,
              video: "https://youtu.be/EmguMw8d6uU",
            },
            {
              title: "Depression and Anxiety",
              cover: daCover,
              pdf: DepressionAndAnxietyBooklet,
              length: 18,
              video: "https://youtu.be/e5569fhg6ps",
            },
          ]}
        />
        <h4 className="mt-4">Mental Health Resources</h4>
        <MentalHealthResources />
      </>
    ),
  },
  "Food-Distribution-in-Mississauga": {
    title: "Food Distribution in Mississauga, Canada",
    top: (
      <GalleryCarousel>
        {foodImages.map((el, i) => (
          <div className="gallery-carousel__item" key={el}>
            <img className="gallery-carousel__img" src={el} alt={`food${i}`} />
          </div>
        ))}
      </GalleryCarousel>
    ),
    bottom: (
      <>
        <p>
          The energetic team of Sinai Foundation distributed food packs on
          Eid-ul-Adha, 2019 to the Muslim Welfare Centre in Mississauga,
          Ontario. In May 2020, with the sponsorship of the Rotary Foundation,
          we distributed food packs to people in Mississauga, Canada.
        </p>
      </>
    ),
  },
  "First-Aid-Courses": {
    title: "Providing First Aid Training Workshops",
    top: (
      <GalleryCarousel>
        {firstAidList.map((el, i) => (
          <div className="gallery-carousel__item" key={el}>
            <img
              className="gallery-carousel__img"
              src={el}
              alt={`workshop-${i}`}
            />
          </div>
        ))}
      </GalleryCarousel>
    ),
    bottom: (
      <>
        <p>
          Sinai Foundation Canada gave the opportunity to individuals who were
          interested in attending a First Aid and CPR training workshop to
          attend at a discounted rate. This training workshop affiliated with
          the Canadian Red Cross provides participants with a completion
          certification and helps individuals develop vital life-saving
          knowledge and skills essential for not only saving a life, but also
          for those looking to meet a variety of workplace requirements.
        </p>
        <BookletReader
          booklets={[
            {
              title: "CPR & First Aid Booklet",
              pdf: CprAndFirstAidBooklet,
              cover: cprCover,
              length: 11,
              video: "https://www.youtube.com/watch?v=pNYgzZcDa6I",
            },
          ]}
        />
      </>
    ),
  },

  // New entry
  // "New Project Launch": {
  //   title:
  //     "Connecting Seniors, Enriching Communities (Project Launch - March 2025)",
  //   top: (
  //     <GalleryCarousel>
  //       {firstAidList.map((el, i) => (
  //         <div className="gallery-carousel__item" key={el}>
  //           <img
  //             className="gallery-carousel__img"
  //             src={el}
  //             alt={`workshop-${i}`}
  //           />
  //         </div>
  //       ))}
  //     </GalleryCarousel>
  //   ),
  //   bottom: (
  //     <>
  //       <p>
  //         Sinai Foundation Canada gave the opportunity to individuals who were
  //         interested in attending a First Aid and CPR training workshop to
  //         attend at a discounted rate. This training workshop affiliated with
  //         the Canadian Red Cross provides participants with a completion
  //         certification and helps individuals develop vital life-saving
  //         knowledge and skills essential for not only saving a life, but also
  //         for those looking to meet a variety of workplace requirements.
  //       </p>
  //       <BookletReader
  //         booklets={[
  //           {
  //             title: "CPR & First Aid Booklet",
  //             pdf: CprAndFirstAidBooklet,
  //             cover: cprCover,
  //             length: 11,
  //             video: "https://www.youtube.com/watch?v=pNYgzZcDa6I",
  //           },
  //         ]}
  //       />
  //     </>
  //   ),
  // },

  "Foodbank-Resources": {
    title: "Foodbank Resources",
    bottom: (
      <>
        <h4 className="mt-4">Foodbank Resources in Mississauga</h4>
        <FoodbankResources />

        <h4 className="mt-4">Food banks in other regions:</h4>
        <OtherRegionsFoodbank />

        <h4 className="mt-4">Other food bank-related services</h4>
        <OtherFoodbankResources />
      </>
    ),
  },
  "Community-Capacity-Building-Initiative": {
    title: "Community Capacity Building Initiative",
    top: <></>,
    bottom: (
      <>
        <p>
          Sinai Foundation Canada participated in the Canada Summer Job program
          with Employment and Social Development Canada (ESDC) and hired four
          university students for the summer. The program started in May 2021
          and continued until July 2021. For this program, Sinai Foundation
          Canada also hired an additional expert in IT to help the students
          during the program. The aim of this project was to provide continuous
          coaching and mentoring to the students and give them the opportunity
          to not only gain work experience but also develop new skills, enhance
          their previous skills and network for future career opportunities.
          Through this program the students were offered 2 opportunities, the
          first was to execute a real-life project they developed as well as an
          e-commerce website, a business website, and a database application.
          The second was to teach JavaScript and Python related classes to high
          school students. The participants spent a total of 1050 hours and
          successfully completed the projects on time and within the budget.
        </p>

        <div className="img-container">
          <img src={esdc} alt="ESDC" />
        </div>
      </>
    ),
  },
  "Health-and-Wellbeing-Stress-Management-Sessions": {
    title: "Health and Wellbeing - Stress Management Sessions",
    top: <></>,
    bottom: (
      <>
        <p>
          Where COVID-19 caused the loss of lives and grief, it also caused
          fear, social isolation, and stress to individuals, especially seniors
          and single parents. Sinai Foundation Canada took additional measures
          to provide stress management sessions delivered by professionals for
          seniors and single parents from August 2020 to February 2021. These
          sessions included: light music therapy, exercises, group
          games/activities, and social discussions for engagements. These online
          sessions offered a healthy outcome for the participants and helped
          reduce their mental stress and anxiety caused by the isolation.
        </p>
      </>
    ),
  },
  "Community-Rapid-Response-Initiative": {
    title: "Community Rapid Response Initiative",
    top: <></>,
    bottom: (
      <>
        <p>
          As the COVID-19 pandemic intensified, our team at Sinai Foundation
          Canada started to brainstorm ways to respond to help the community. In
          March 2020, when the nationwide lockdowns began, our team began to
          gather data regarding the most vulnerable seniors in Peel Region so
          they could be helped. The main objective was to help the most
          vulnerable, stay safe during the COVID-19 pandemic. Senior Tamil’s
          society of Peel, Rotary Club Streetsville, Rotary Club Malton, Share
          and Care Foodbank and The Mississauga Food Bank were all contacted to
          help fulfill the mission. The team at Sinai Foundation Canada also
          started their first free grocery delivery to seniors in Peel Region
          (Brampton, Malton, Mississauga). In May 2020, the Rotary Club
          Streetsville extended its hand to provide further support and awarded
          Sinai Foundation Canada a grant worth $4000 that went towards the free
          grocery program. In August 2020, Sinai Foundation Canada applied for a
          Federal Government grant through Red Cross Canada and was awarded an
          additional grant to support 50 seniors in the area for four months. As
          the word of mouth grew larger, the demand increased, and many single
          parents and refugees started asking for help. In September 2020, Sinai
          Foundation Canada received an additional Federal grant to help support
          50 single parents. In the end, the project was a success; the team at
          Sinai Foundation Canada successfully provided a service for 150
          families with free groceries until the end of February 2021. Despite
          the fact that it was difficult and challenging at times since there
          were no commercial locations and logistics, the team and volunteers at
          Sinai Foundation Canada were able to succeed with pride.
        </p>
        <p>Service Provided:</p>
        <ul>
          <li>Free premium groceries</li>
          <li>Specific dietary tailored items and general medical supplies</li>
          <li>
            Provided literature for personal protection awareness and PPE items
            such as gloves, masks and sanitizers
          </li>
          <li>Infant formula, food and diapers</li>
        </ul>
      </>
    ),
  },
  "": {
    title: "",
    top: <></>,
    bottom: (
      <>
        {/* <p>
          In February 2020, Sinai Foundation Canada started a pilot project in
          Mississauga in calibration with Evergreen Retirement Home and offered
          a fitness program for its residents. The program was successful and
          residents enjoyed Tai Chi classes with trained instructors. The
          program offered great flexibility as some of the seniors had mobility
          issues and modified arrangements were offered for them to remain in
          their chairs but still do some of the mobility exercises. These
          activities helped enhance and improve their mobility and motor skills,
          and also provided them the opportunity to engage in a social setting.
          Unfortunately, as the COVID-19 lockdowns began, the classes were
          discontinued due to safety concerns.
        </p> */}
      </>
    ),
  },
  "Senior-Home-Facility-Rock-Smooth-Falls": {
    title: "Senior Home Facility - Rock Smooth Falls ",
    top: <></>,
    bottom: (
      <>
        <p>
          In 2017, the City of Smooth Falls, (Northern Ontario) started an
          initiative to increase business acidity and rebuild the city's
          population. The team at Sinai Foundation Canada was invited to present
          a project that could increase services and employment. Our team
          proposed to develop a senior home with 80 beds contingent on the city
          and government funding. Rock Smooth Falls is a small town with a
          population of almost 700 people. In 2016, this town had a population
          of 13,448. The Smooth Rock Falls has mix population of Natives,
          English and French-speaking families. In 2006, the biggest employer,
          Tembec Malette Pulp, a paper mill, closed and caused population
          displacement and numbers then decreased. Our project was approved by
          the city but unfortunately, the required funding to build the facility
          was not secured to complete the construction. This project was then
          put on hold till a suitable time, and the land was returned back to
          the city in 2020.
        </p>
      </>
    ),
  },
  "Solar-Panel-Installation-in-Sindh": {
    title: "Solar Panel Installation in Sindh, Pakistan",
    top: (
      <GalleryCarousel>
        {solarImages.map((el, i) => (
          <div className="gallery-carousel__item" key={el}>
            <img
              className="gallery-carousel__img"
              src={el}
              alt={`solar-${i}`}
            />
          </div>
        ))}
      </GalleryCarousel>
    ),
    bottom: (
      <p>
        Sinai Foundation completed the project for solar panels provisioning to
        the people who live in huts in a village.
      </p>
    ),
  },
  "Brick-Paving-in-Sindh": {
    title: "Brick Paving in Sindh, Pakistan",
    top: (
      <GalleryCarousel>
        {brickList.map((el, i) => (
          <div className="gallery-carousel__item" key={el}>
            <img className="gallery-carousel__img" src={el} alt={`food-${i}`} />
          </div>
        ))}
      </GalleryCarousel>
    ),
    bottom: (
      <p>
        The energetic team of Sinai Foundation completed the project for brick
        paving on the way from one village to another.
      </p>
    ),
  },
  "Raised-Funds-for-Ethiopia-crisis-during-Ramadan-2021": {
    title: "Raised Funds for Ethiopia crisis during Ramadan 2021",
    top: <></>,
    bottom: (
      <>
        <p>
          <a
            className="external-link"
            href="https://www.unhcr.org/"
            target="_blank"
            rel="noreferrer"
          >
            UNHCR Canada
          </a>{" "}
          Sinai Foundation Canada is proud to have partnered with UNHCR to raise
          funds for the crisis that took place in Ethiopia during Ramadan of
          2021. To this day, we continue our partnership with the UNHCR,
          participating to help the Yemen and Afghan crises.
        </p>
        <div className="img-container">
          <img src={unhcr} alt="UNHCR" />
        </div>
      </>
    ),
  },
  "Providing-Help-for-Beirut": {
    title: "Providing Help for Beirut",
    top: <></>,
    bottom: (
      <>
        <p>
          <a
            className="external-link"
            href="https://www.inara.org"
            target="_blank"
            rel="noreferrer"
          >
            INARA
          </a>{" "}
          - 0On August 4th, 2020, a massive explosion took the city of Beirut by
          storm. The explosion at the seaport left more than 200 dead, thousands
          injured, and hospitals out of service. Sinai Foundation Canada is
          proud to have partnered with INARA to provide help for Beirut and
          directly provide further support to 108 beneficiaries. In the future,
          we will work on other projects in order to provide for those in need,
          especially during a crisis.
        </p>
        <div className="img-container">
          <img src={inara} alt="INARA" />
        </div>
      </>
    ),
  },
};
