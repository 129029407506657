// File containing all project descriptions; similar to the info page for the board members

import React from "react";

// component imports
import BookletReader from "../../../components/BookletReader/BookletReader";
import FoodbankResources from "../../../components/FoodbankResources";
import GalleryCarousel from "../../../components/GalleryCarousel";
import MentalHealthResources from "../../../components/MentalHealthResources";
import OtherFoodbankResources from "../../../components/OtherFoodbankResources";
import OtherRegionsFoodbank from "../../../components/OtherRegionsFoodbank";

// Sinai project image imports
import food1 from "../../../assets/Projects/food1.jpeg";
import food2 from "../../../assets/Projects/food2.jpg";
import food3 from "../../../assets/Projects/food3.jpg";
import food4 from "../../../assets/Projects/food4.jpg";
import food5 from "../../../assets/Projects/food5.jpg";

import solar1 from "../../../assets/Projects/solar1.jpeg";
import solar2 from "../../../assets/Projects/solar2.jpeg";
import solar3 from "../../../assets/Projects/solar3.jpeg";
import solar4 from "../../../assets/Projects/solar4.jpeg";

import brick1 from "../../../assets/Projects/brick1.jpeg";
import brick2 from "../../../assets/Projects/brick2.jpeg";

import group from "../../../assets/Projects/group.jpeg";
import training from "../../../assets/Projects/training.jpeg";
import training2 from "../../../assets/Projects/training2.jpeg";
import training3 from "../../../assets/Projects/training3.jpeg";
import training4 from "../../../assets/Projects/training4.jpeg";

import beirut from "../../../assets/images/beirut.jpg";

import flood1 from "../../../assets/images/flood1.jpg";
import flood2 from "../../../assets/images/flood2.jpg";

// partners logo imports
import esdc from "../../../assets/Logos/ESDC.png";
import unhcr from "../../../assets/Logos/UNHCRLogo2.png";

// booklet cover imports
import eiCover from "../../../assets/Booklets/Emotional_Intellignce/cover.jpg";
import smCover from "../../../assets/Booklets/Stress_Management/cover.jpg";
import daCover from "../../../assets/Booklets/Depression_And_Anxiety/cover.jpg";
import cprCover from "../../../assets/Booklets/Cpr_And_First_Aid_Booklet/cover.jpg";

// document imports
import EmotionalIntelligenceBooklet from "../../../assets/Booklets/Emotional_Intellignce/Booklet.pdf";
import StressManagementBooklet from "../../../assets/Booklets/Stress_Management/Booklet.pdf";
import DepressionAndAnxietyBooklet from "../../../assets/Booklets/Depression_And_Anxiety/Booklet.pdf";
import CprAndFirstAidBooklet from "../../../assets/Booklets/Cpr_And_First_Aid_Booklet/Booklet.pdf";

// lists of images that will be used in carousels
const foodImages = [food1, food2, food3, food4, food5];
const solarImages = [solar1, solar4, solar2, solar3];
const brickList = [brick1, brick2];
const firstAidList = [group, training, training2, training3, training4];

const toggleAccordion = (e) => {
  const element = e.currentTarget;
  const content = element.nextElementSibling;
  element.classList.toggle("active");
  if (content.style.maxHeight) {
    content.style.maxHeight = null;
  } else {
    content.style.maxHeight = content.scrollHeight + "px";
  }
};

// Attach this function to the accordion-new__title in your JSX
<div className="accordion-new__title" onClick={toggleAccordion}>
  Advisory Input
</div>;

// map of each page, with the link being the key and the value being the site's contents
export const descriptions = {
  "Mental-Health-Project": {
    title: "Mental Health Project",
    bottom: (
      <>
        <p>
          During Sinai Foundation Canada's participation in the Canada Summer
          Job program for the year 2022, six university students worked together
          to develop an initiative surrounding mental health to promote mental
          health awareness and educational resources. The students from
          different fields worked together and benefited from this program as
          they advanced their research, problem-solving, collaboration, critical
          thinking and many more skills which will help them in their
          professional life and future careers.
          <br />
          <br />
          The project was developed with the aim to promote mental health
          awareness and educational resources. As part of this initiative, we
          developed different educational booklets involving stress management,
          emotional intelligence and anxiety and depression in order to dispense
          knowledge on what these topic areas include and how to navigate/cope
          if we are directly experiencing any of the related mental health
          issues. Furthermore, a section was added to our website for further
          resources and support from different organizations that individuals
          can access.
        </p>

        <h2 className="subheader mt-4 mb-4" style={{ textAlign: "center" }}>
          Check out our booklets!
        </h2>
        {/* Booklet reader requires the title, length, cover, and pdf to the booklet */}
        <BookletReader
          booklets={[
            {
              title: "Emotional Intelligence",
              pdf: EmotionalIntelligenceBooklet,
              cover: eiCover,
              length: 11,
              video: "https://youtu.be/QHzZKpj7Y6o",
            },
            {
              title: "Stress Management",
              cover: smCover,
              pdf: StressManagementBooklet,
              length: 12,
              video: "https://youtu.be/EmguMw8d6uU",
            },
            {
              title: "Depression and Anxiety",
              cover: daCover,
              pdf: DepressionAndAnxietyBooklet,
              length: 18,
              video: "https://youtu.be/e5569fhg6ps",
            },
          ]}
        />
        <h4 className="mt-4">Mental Health Resources</h4>
        <MentalHealthResources />
      </>
    ),
  },
  "Food-Distribution-in-Mississauga": {
    title: "Food Distribution in Mississauga, Canada",
    top: (
      <GalleryCarousel>
        {foodImages.map((el, i) => (
          <div className="gallery-carousel__item" key={el}>
            <img className="gallery-carousel__img" src={el} alt={`food${i}`} />
          </div>
        ))}
      </GalleryCarousel>
    ),
    bottom: (
      <>
        <p>
          The energetic team of Sinai Foundation distributed food packs on
          Eid-ul-Adha, 2019 to the Muslim Welfare Centre in Mississauga,
          Ontario. In May 2020, with the sponsorship of the Rotary Foundation,
          we distributed food packs to people in Mississauga, Canada.
        </p>
      </>
    ),
  },
  "First-Aid-Courses": {
    title: "Providing First Aid Training Workshops",
    top: (
      <GalleryCarousel>
        {firstAidList.map((el, i) => (
          <div className="gallery-carousel__item" key={el}>
            <img
              className="gallery-carousel__img"
              src={el}
              alt={`workshop-${i}`}
            />
          </div>
        ))}
      </GalleryCarousel>
    ),
    bottom: (
      <>
        <p>
          Sinai Foundation Canada gave the opportunity to individuals who were
          interested in attending a First Aid and CPR training workshop to
          attend at a discounted rate. This training workshop affiliated with
          the Canadian Red Cross provides participants with a completion
          certification and helps individuals develop vital life-saving
          knowledge and skills essential for not only saving a life, but also
          for those looking to meet a variety of workplace requirements.
        </p>
        <BookletReader
          booklets={[
            {
              title: "CPR & First Aid Booklet",
              pdf: CprAndFirstAidBooklet,
              cover: cprCover,
              length: 11,
              video: "https://www.youtube.com/watch?v=pNYgzZcDa6I",
            },
          ]}
        />
      </>
    ),
  },
  "New Project Launch": {
    title:
      "Connecting Seniors, Enriching Communities (Project Launch - March 2025)",
    bottom: (
      <>
        {/* Program Introduction */}
        <p>
          <strong>Introducing Our New Program:</strong> Seniors Living Healthy
          and Contributing with Youth <br />
          Launching March 2025, we are excited to announce the upcoming launch
          of our newest initiative at Sinai Foundation Canada—Seniors Living
          Healthy and Contributing with Youth. This program, set to begin in
          March 2025, builds on our previous efforts to reduce social isolation
          through exercise and music therapy, now with a fresh focus on
          intergenerational collaboration, health, safety, and community
          engagement.
        </p>

        {/* Section 1: A Natural Extension of Our Commitment */}
        <p>
          <strong>A Natural Extension of Our Commitment</strong> <br />
          Our previous program helped seniors combat social isolation with
          activities like exercise and music therapy, enhancing physical and
          emotional well-being. Now, we’re expanding this focus by introducing
          educational opportunities and interactive sessions that encourage
          seniors to engage with youth and contribute to the community.
        </p>

        {/* Section 2: What’s New in the March 2025 Program */}
        <p>
          <strong>What’s New in the March 2025 Program?</strong> <br />
          1. <strong>Expanding Health and Wellness Offerings</strong>
          <br />
          <em>Health and Wellness Workshops:</em> In addition to exercise and
          music therapy, we’re adding workshops focused on healthy eating,
          nutrition, and overall well-being. <br />
          <em>Outdoor Activities:</em> Group activities like walking clubs and
          gardening will promote physical fitness and social interaction,
          helping seniors stay active and engaged.
          <br />
          <br />
          2. <strong>New Awareness Sessions</strong> <br />
          Starting in March 2025, we will host sessions to raise awareness on
          critical topics such as:
          <br />
          <em>Fraud Prevention:</em> Learn to spot and avoid scams targeting
          seniors, with practical tips for staying safe.
          <br />
          <em>Personal Safety:</em> Gain important safety strategies to feel
          secure at home and in the community.
          <br />
          <em>Personal Taxes:</em> Attend workshops to help seniors understand
          tax filing, focusing on benefits, credits, and deductions relevant to
          them.
          <br />
          <br />
          3. <strong>Strengthening Intergenerational Connections</strong>
          <br />
          A key focus of this new program is bridging the gap between seniors
          and youth through:
          <br />
          <em>Mentorship Opportunities:</em> Seniors can share life experiences
          with youth, while younger participants teach digital literacy and
          technology skills.
          <br />
          <em>Joint Community Projects:</em> Seniors and youth will collaborate
          on projects that benefit the local community.
          <br />
          <br />
          4. <strong>Reducing Social Isolation</strong>
          <br />
          Beyond promoting social activities and physical health, the program
          will help seniors stay connected through:
          <br />
          <em>Volunteerism:</em> Opportunities for seniors to mentor, lead
          workshops, and engage in meaningful community activities.
          <br />
          <em>Social Events and Support Circles:</em> Regular gatherings to
          connect with peers and younger generations, building lasting
          relationships and combating loneliness.
        </p>

        {/* Program Conclusion */}
        <p>
          <strong>The Evolution of Our Senior Programs</strong> <br />
          Our previous success in promoting physical and emotional well-being
          has laid the foundation for **Seniors Living Healthy and Contributing
          with Youth**. By including sessions on fraud prevention, personal
          taxes, and safety, along with encouraging intergenerational
          interactions, we aim to empower seniors to live healthier, more
          fulfilling lives.
        </p>

        {/* Call to Action */}
        <p>
          <strong>Join Us in March 2025!</strong> <br />
          Our new program launches in March 2025! If you're a senior or youth
          interested in getting involved, learning new skills, or building
          connections, we invite you to be part of this exciting opportunity.{" "}
          <br />
          Contact us today at{" "}
          <a href="mailto:akhtermobin1@gmail.com">akhtermobin1@gmail.com</a> or
          call: (833) 905-1260 to learn more about the program and how you can
          participate!
        </p>
      </>
    ),
  },

  "Foodbank-Resources": {
    title: "Foodbank Resources",
    bottom: (
      <>
        <h4 className="mt-4">Foodbank Resources in Mississauga</h4>
        <FoodbankResources />

        <h4 className="mt-4">Food banks in other regions:</h4>
        <OtherRegionsFoodbank />

        <h4 className="mt-4">Other food bank-related services</h4>
        <OtherFoodbankResources />
      </>
    ),
  },
  "Community-Capacity-Building-Initiative": {
    title: "Community Capacity Building Initiative",
    top: <></>,
    bottom: (
      <>
        <p>
          Sinai Foundation Canada participated in the Canada Summer Job program
          with Employment and Social Development Canada (ESDC) and hired four
          university students for the summer. The program started in May 2021
          and continued until July 2021. For this program, Sinai Foundation
          Canada also hired an additional expert in IT to help the students
          during the program. The aim of this project was to provide continuous
          coaching and mentoring to the students and give them the opportunity
          to not only gain work experience but also develop new skills, enhance
          their previous skills and network for future career opportunities.
          Through this program the students were offered 2 opportunities, the
          first was to execute a real-life project they developed as well as an
          e-commerce website, a business website, and a database application.
          The second was to teach JavaScript and Python related classes to high
          school students. The participants spent a total of 1050 hours and
          successfully completed the projects on time and within the budget.
        </p>

        <div className="img-container">
          <img src={esdc} alt="ESDC" />
        </div>
      </>
    ),
  },
  "Health-and-Wellbeing-Stress-Management-Sessions": {
    title: "Health and Wellbeing - Stress Management Sessions",
    top: <></>,
    bottom: (
      <>
        <p>
          Where COVID-19 caused the loss of lives and grief, it also caused
          fear, social isolation, and stress to individuals, especially seniors
          and single parents. Sinai Foundation Canada took additional measures
          to provide stress management sessions delivered by professionals for
          seniors and single parents from August 2020 to February 2021. These
          sessions included: light music therapy, exercises, group
          games/activities, and social discussions for engagements. These online
          sessions offered a healthy outcome for the participants and helped
          reduce their mental stress and anxiety caused by the isolation.
        </p>
      </>
    ),
  },
  "Community-Rapid-Response-Initiative": {
    title: "Community Rapid Response Initiative",
    top: <></>,
    bottom: (
      <>
        <p>
          As the COVID-19 pandemic intensified, our team at Sinai Foundation
          Canada started to brainstorm ways to respond to help the community. In
          March 2020, when the nationwide lockdowns began, our team began to
          gather data regarding the most vulnerable seniors in Peel Region so
          they could be helped. The main objective was to help the most
          vulnerable, stay safe during the COVID-19 pandemic. Senior Tamil’s
          society of Peel, Rotary Club Streetsville, Rotary Club Malton, Share
          and Care Foodbank and The Mississauga Food Bank were all contacted to
          help fulfill the mission. The team at Sinai Foundation Canada also
          started their first free grocery delivery to seniors in Peel Region
          (Brampton, Malton, Mississauga). In May 2020, the Rotary Club
          Streetsville extended its hand to provide further support and awarded
          Sinai Foundation Canada a grant worth $4000 that went towards the free
          grocery program. In August 2020, Sinai Foundation Canada applied for a
          Federal Government grant through Red Cross Canada and was awarded an
          additional grant to support 50 seniors in the area for four months. As
          the word of mouth grew larger, the demand increased, and many single
          parents and refugees started asking for help. In September 2020, Sinai
          Foundation Canada received an additional Federal grant to help support
          50 single parents. In the end, the project was a success; the team at
          Sinai Foundation Canada successfully provided a service for 150
          families with free groceries until the end of February 2021. Despite
          the fact that it was difficult and challenging at times since there
          were no commercial locations and logistics, the team and volunteers at
          Sinai Foundation Canada were able to succeed with pride.
        </p>
        <p>Service Provided:</p>
        <ul>
          <li>Free premium groceries</li>
          <li>Specific dietary tailored items and general medical supplies</li>
          <li>
            Provided literature for personal protection awareness and PPE items
            such as gloves, masks and sanitizers
          </li>
          <li>Infant formula, food and diapers</li>
        </ul>
      </>
    ),
  },
  "Seniors-Wellbeing-Project-in-Mississauga": {
    title:
      "Seniors Living Healthy and Contributing with Youth (New Program 2025)",
    top: <></>,
    bottom: (
      <>
        <div className="seniors-youth-program">
          <div className="seniors-youth-program__header">
            <p>
              A new initiative fostering intergenerational collaboration and
              community well-being.
            </p>
          </div>

          <div className="seniors-youth-program__content">
            {/* Roles of Seniors */}
            <div className="content-block">
              <h3>Roles of Seniors</h3>
              <p>
                <strong>Advisory Input:</strong> Seniors will be invited to
                participate in focus groups and advisory committees to provide
                input on the program's design and content. Their feedback will
                help shape the specific topics and formats of the health
                workshops, social activities, and educational sessions.
              </p>
              <p>
                <strong>Program Development:</strong> Seniors will have the
                opportunity to provide ongoing input to the curriculum for
                various workshops, such as those on healthy eating, fraud
                prevention, and personal safety. Their lived experiences and
                knowledge will be invaluable in tailoring these sessions to
                address real concerns and interests.
              </p>
              <p>
                <strong>Mentorship and Leadership:</strong> Seniors will take on
                mentorship roles, guiding youth through collaborative projects
                and sharing their expertise. They will also lead some of the
                educational discussions and social activities, leveraging their
                skills and experiences to enrich the learning environment.
              </p>
              <p>
                <strong>Feedback and Evaluation:</strong> Throughout the
                program, seniors will provide ongoing feedback and participate
                in evaluations to assess the effectiveness of the activities and
                make recommendations for improvements. Their insights will help
                ensure that the program remains relevant and impactful.
              </p>
            </div>

            {/* Roles of Youth */}
            <div className="content-block">
              <h3>Roles of Youth</h3>
              <p>
                <strong>Program Design and Implementation:</strong> Youth will
                be involved in brainstorming sessions and design workshops to
                contribute ideas for engaging activities and workshops. Their
                perspective will help ensure the program includes modern,
                relevant topics and activities that appeal to both seniors and
                themselves.
              </p>
              <p>
                <strong>Technology and Innovation:</strong> Youth will assist in
                incorporating technology into the program, such as setting up
                online resources, facilitating virtual workshops, and creating
                multimedia content for educational purposes. Their digital
                skills will be crucial in enhancing the program’s accessibility
                and effectiveness.
              </p>
              <p>
                <strong>Collaboration and Leadership:</strong> Youth will
                actively participate in the intergenerational projects, working
                alongside seniors in community service initiatives and
                collaborative events. They will also take leadership roles in
                organizing and executing some of the program’s activities,
                fostering a sense of shared responsibility.
              </p>
              <p>
                <strong>Feedback and Adaptation:</strong> Youth will provide
                feedback on their experiences and suggest ways to improve the
                program. Their input will be essential in ensuring the program
                remains dynamic and appealing to younger participants while
                addressing the needs of seniors.
              </p>
            </div>

            {/* Collaborative Planning Process */}
            <div className="content-block">
              <h3>Collaborative Planning Process</h3>
              <p>
                <strong>Joint Planning Sessions:</strong> Seniors and youth will
                be involved in joint planning sessions where they can share
                ideas, discuss their expectations, and collaborate on the
                program’s design. These sessions will facilitate open
                communication and mutual understanding between the two groups.
              </p>
              <p>
                <strong>Ongoing Dialogue:</strong> Regular meetings and feedback
                sessions will be held throughout the program to ensure
                continuous input from both seniors and youth. This ongoing
                dialogue will help adapt the program as needed and address any
                emerging issues or opportunities.
              </p>
            </div>

            {/* Benefits for Seniors */}
            <div className="content-block">
              <h3>Benefits for Seniors</h3>
              <p>
                <strong>Improved Health and Wellness:</strong> Physical activity
                sessions and outdoor exercises will help seniors improve their
                physical health, mobility, and maintain an active lifestyle,
                while wellness workshops will support mental health.
              </p>
              <p>
                <strong>Enhanced Knowledge and Skills:</strong> Sessions on
                healthy eating, fraud prevention, personal safety, and personal
                taxes will provide seniors with valuable information to manage
                their health, finances, and security more effectively.
                Interaction with youth will help seniors become more familiar
                with digital tools and online resources, improving their ability
                to navigate technology.
              </p>
              <p>
                <strong>Increased Social Engagement:</strong> Seniors will
                engage in intergenerational projects and volunteering
                opportunities, building meaningful connections with both their
                peers and younger generations.
              </p>
              <p>
                <strong>Mentorship Opportunities:</strong> Seniors will have the
                chance to mentor youth, sharing wisdom and leading community
                projects, providing a sense of fulfillment and leadership in the
                community.
              </p>
            </div>

            {/* Benefits for Youth */}
            <div className="content-block">
              <h3>Benefits for Youth</h3>
              <p>
                <strong>Personal Growth and Development:</strong> Youth will
                gain valuable mentorship from seniors, helping them develop
                leadership, teamwork, and organizational skills through
                community service and project collaboration.
              </p>
              <p>
                <strong>Enhanced Understanding and Empathy:</strong> Working
                closely with seniors will foster empathy and respect, deepening
                youth’s understanding of the challenges and experiences faced by
                older generations.
              </p>
              <p>
                <strong>Skill Development:</strong> Youth will have the
                opportunity to apply their technological expertise to assist
                seniors, which can enhance their own skills and create a
                positive impact.
              </p>
              <p>
                <strong>Increased Social and Community Engagement:</strong>{" "}
                Youth will actively participate in community service, building
                meaningful relationships with seniors and contributing to the
                overall well-being of the community.
              </p>
            </div>

            {/* Benefits for the Community */}
            <div className="content-block">
              <h3>Benefits for the Community</h3>
              <p>
                <strong>Strengthened Community Bonds:</strong> The program will
                enhance connections between different generations, fostering a
                more cohesive and supportive community.
              </p>
              <p>
                <strong>Increased Social Inclusion:</strong> By addressing
                social isolation and providing opportunities for engagement, the
                project will promote a more inclusive environment for all
                community members.
              </p>
              <p>
                <strong>Enhanced Community Well-being:</strong> The combined
                focus on health, safety, and intergenerational collaboration
                will contribute to the overall well-being and vitality of the
                community.
              </p>
            </div>
          </div>
        </div>
      </>
    ),
  },
  "Senior-Home-Facility-Rock-Smooth-Falls": {
    title: "Senior Home Facility - Rock Smooth Falls ",
    top: <></>,
    bottom: (
      <>
        <p>
          In 2017, the City of Smooth Falls, (Northern Ontario) started an
          initiative to increase business acidity and rebuild the city's
          population. The team at Sinai Foundation Canada was invited to present
          a project that could increase services and employment. Our team
          proposed to develop a senior home with 80 beds contingent on the city
          and government funding. Rock Smooth Falls is a small town with a
          population of almost 700 people. In 2016, this town had a population
          of 13,448. The Smooth Rock Falls has mix population of Natives,
          English and French-speaking families. In 2006, the biggest employer,
          Tembec Malette Pulp, a paper mill, closed and caused population
          displacement and numbers then decreased. Our project was approved by
          the city but unfortunately, the required funding to build the facility
          was not secured to complete the construction. This project was then
          put on hold till a suitable time, and the land was returned back to
          the city in 2020.
        </p>
      </>
    ),
  },
  "Solar-Panel-Installation-in-Sindh": {
    title: "Solar Panel Installation in Sindh, Pakistan",
    top: (
      <GalleryCarousel>
        {solarImages.map((el, i) => (
          <div className="gallery-carousel__item" key={el}>
            <img
              className="gallery-carousel__img"
              src={el}
              alt={`solar-${i}`}
            />
          </div>
        ))}
      </GalleryCarousel>
    ),
    bottom: (
      <p>
        Sinai Foundation completed the project for solar panels provisioning to
        the people who live in huts in a village.
      </p>
    ),
  },
  "Brick-Paving-in-Sindh": {
    title: "Brick Paving in Sindh, Pakistan",
    top: (
      <GalleryCarousel>
        {brickList.map((el, i) => (
          <div className="gallery-carousel__item" key={el}>
            <img className="gallery-carousel__img" src={el} alt={`food-${i}`} />
          </div>
        ))}
      </GalleryCarousel>
    ),
    bottom: (
      <p>
        The energetic team of Sinai Foundation completed the project for brick
        paving on the way from one village to another.
      </p>
    ),
  },
  "Raised-Funds-for-Ethiopia-crisis-during-Ramadan-2021": {
    title: "Raised Funds for Ethiopia crisis during Ramadan 2021",
    top: <></>,
    bottom: (
      <>
        <p>
          <a
            className="external-link"
            href="https://www.unhcr.org/"
            target="_blank"
            rel="noreferrer"
          >
            UNHCR Canada
          </a>{" "}
          Sinai Foundation Canada is proud to have partnered with UNHCR to raise
          funds for the crisis that took place in Ethiopia during Ramadan of
          2021. To this day, we continue our partnership with the UNHCR,
          participating to help the Yemen and Afghan crises.
        </p>
        <div className="img-container">
          <img src={unhcr} alt="UNHCR" />
        </div>
      </>
    ),
  },
  "Catastrophe-and-Relief-Efforts---city-of-Beirut": {
    title: "Catastrophe and Relief Efforts - city of Beirut",
    top: <></>,
    bottom: (
      <div className="main-body">
        <p>
          On August 4, 2020, Beirut was devastated by a massive explosion caused
          by 2,750 tons of improperly stored ammonium nitrate at the city's
          port. The blast, one of the most powerful non-nuclear explosions on
          record, resulted in at least 218 deaths, 7,000 injuries, and around
          $15 billion in property damage, leaving approximately 300,000 people
          homeless. The explosion was felt in neighboring countries and as far
          away as Cyprus, registering as a magnitude 3.3 seismic event.
        </p>
        <p>
          The explosion's immediate aftermath saw suspicions of terrorist
          organization’s involvement. The Lebanese government declared a
          two-week state of emergency, and public outrage over the incident led
          to widespread protests and the resignation of Prime Minister Hassan
          Diab and his cabinet on August 10, 2020.
        </p>
        <p>
          Long-term consequences included severe damage to adjacent grain silos,
          which partially collapsed in mid-2022 following fires in the remaining
          grain.
        </p>
        <p>
          In response to this disaster, <b>Sinai Foundation Canada</b>{" "}
          collaborated with{" "}
          <a
            className="external-link"
            href="https://www.inara.org"
            target="_blank"
            rel="noreferrer"
          >
            INARA
          </a>{" "}
          to provide relief to the victims, demonstrating a commitment to
          supporting those affected by the tragedy and helping to rebuild the
          community.
        </p>

        <img src={beirut} alt="BEIRUT" />
      </div>
    ),
  },
  "Skill-Development-Program": {
    title: "Skill Development Program",
    top: <></>,
    bottom: (
      <>
        <p>Placeholder.</p>
      </>
    ),
  },
  "South-Asian-Community-Wellness-and-Engagement": {
    title: "South Asian Community Wellness and Engagement",
    top: <></>,
    bottom: (
      <>
        <p>Placeholder.</p>
      </>
    ),
  },
  "Ecological-Grief-Program": {
    title: "Ecological Grief Program",
    top: <></>,
    bottom: (
      <>
        <p>
          During the COVID-19 lockdown in 2020-2021, the Sinai Foundation Canada
          recognized the heightened levels of ecological grief and stress within
          the community. In response, we launched a specialized Ecological Grief
          Program aimed at providing emotional and psychological support during
          these challenging times. The program featured a series of online
          sessions that combined music therapy, physical exercises, and virtual
          outdoor activities to help participants manage their eco-anxiety and
          reconnect with nature.
        </p>
        <h4 className="mt-4">Program Highlights:</h4>
        <ul>
          <li>
            <b>Music Therapy:</b> These sessions utilized soothing nature sounds
            and collaborative music-making to help participants express their
            emotions and reduce stress.
          </li>
          <li>
            <b>Physical Exercises:</b> Incorporating elements of yoga and tai
            chi, these exercises were designed to promote physical well-being
            and mental relaxation.
          </li>
          <li>
            <b>Virtual Outdoor Activities:</b> Guided nature walks and
            mindfulness practices were conducted online to help participants
            feel connected to the natural world, despite the lockdown
            constraints.
          </li>
        </ul>
        <p>
          The program catered to a diverse group of participants, including
          seniors, parents, single parents, and youth, addressing their unique
          needs and fostering a sense of community during an isolating time.
          Additionally, we provided essential services such as free groceries
          delivered to the doors of needy seniors, single parents, and foreign
          students who were quarantined, ensuring their basic needs were met
          during the lockdown.
        </p>
        <p>
          These programs were offered in collaboration with Red Cross Canada and
          the Government of Canada, leveraging their resources and expertise to
          maximize the reach and impact of our efforts. From learning through
          COVID-19 and its effects, Sinai Foundation Canada engaged university
          students to develop resources to address mental health issues as a new
          project, enhancing our commitment to supporting the community's
          well-being.
        </p>
        <p>
          This initiative provided a vital outlet for community members,
          offering solace and support during an unprecedented time of isolation
          and environmental concern. The feedback from participants highlighted
          the program's effectiveness in fostering a sense of community and
          emotional resilience.
        </p>
        <p>
          For more information about our ongoing efforts and future programs,
          please visit{" "}
          <a
            className="external-link"
            href="../../"
            target="_blank"
            rel="noreferrer"
          >
            our website
          </a>{" "}
          or{" "}
          <a
            className="external-link"
            href="../../contact"
            target="_blank"
            rel="noreferrer"
          >
            contact us
          </a>{" "}
          directly.
        </p>
      </>
    ),
  },

  "Flood-Relief-Project-in-Balochistan": {
    title: "Flood Relief Project in Balochistan",
    top: <></>,
    bottom: (
      <div className="main-body">
        <p>
          In response to the severe flooding in Balochistan and other areas of
          Pakistan, Sinai Foundation Canada, in cooperation with Mississauga
          Central Lions Club and Islamabad Crescent Lions Club, successfully
          completed a flood relief project to support affected communities. The
          situation in Balochistan had become increasingly critical due to heavy
          rains and floods, which devastated infrastructure and caused
          significant losses to lives, homes, and personal belongings. Our
          coordinated efforts provided immediate assistance to flood victims by
          delivering essential supplies such as food, shelter, and first aid.
        </p>
        <img src={flood1} alt="FLOOD1" />
        <p>
          This collaborative initiative ensured that the affected communities
          received timely support, helping them recover and rebuild their lives
          in the aftermath of the devastating floods. The dedication and
          coordination between these organizations exemplify the spirit of
          global solidarity and the impact of collective action in
          times of crisis.
        </p>
        <img src={flood2} alt="FLOOD2" />
      </div>
    ),
  },
};
