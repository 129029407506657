import React, { useRef, useState } from "react";
import useOnScreen from "../../Hooks/useOnScreen";
import "./BoardMember.scss";

import logo from "../../assets/SinaiLogo/sinai-logo.svg";

export default function BoardMember({
  memberName,
  memberTitle,
  memberInfo = "",
  memberImg = logo,
}) {
  const [expanded, setExpanded] = useState(false); // State to track if info is expanded
  const ref = useRef(null);

  const toggleExpand = () => setExpanded(!expanded);

  return (
    <div className={`board-member-card ${useOnScreen(ref) ? "fade-in" : ""}`}>
      <img
        src={memberImg || logo}
        className="board-member-img"
        alt={memberName}
      />
      <div className="board-member-content" ref={ref}>
        <h5 className="board-member-name">{memberName}</h5>
        <h5 className="board-member-title">{memberTitle}</h5>
        <p className={`board-member-info ${expanded ? "expanded" : ""}`}>
          {memberInfo || ""}
        </p>
        {memberInfo && memberInfo.length > 200 && (
          <span className="board-member-show-more" onClick={toggleExpand}>
            {expanded ? "Show Less" : "Show More"}
          </span>
        )}
      </div>
    </div>
  );
}
