import { Link } from "react-router-dom";
import HeroBanner from "../../components/HeroBanner";
import { blogPages } from "./BlogData";
import "./Blog.scss";
import Subscribe from "../../components/Subscribe/Subscribe";

import Report_20_21 from "../../assets/Reports/Annual_Report_20-21.pdf";

/*
  This is how the card on the /blog is laid out, with appropriate styling.
  The Link tag makes it possible to navigate to /blogPage

*/
export function Card(props) {
  return (
    <div className="blog-card h-100">
      <img src={props.img} />
      <div className="card-body">
        <h2 className="card-title">{props.title}</h2>
        <p className="card-body">{props.description}</p>
      </div>
      <Link
        className="blog-card__btn"
        aria-current="page"
        to={"/blog/" + props.id}
      >
        Read More
      </Link>
    </div>
  );
}

// Main export
export default function Blog() {
  return (
    <div className="blog">
      <HeroBanner title="Our Blog" body="Read up on our latest posts here!" />
      <div
        className="row row-cols-xl-3 row-cols-md-2 row-cols-1 g-4 main"
        style={{ width: "calc(100% - 3rem)", marginLeft: "1.5rem" }}
      >
        {blogPages.map(({ id, img, title, description }, key) => (
          <div className="col" key={key}>
            <Card id={id} img={img} title={title} description={description} />
          </div>
        ))}
      </div>

      {/* Embedding YouTube Video */}
      <div className="video-container">
        <h2>Our Latest Video</h2>
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/fkUOjdkU3Ys"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </div>

      <HeroBanner title="Annual Reports" />
      <div
        className="main reports"
        style={{ width: "calc(100% - 3rem)", marginLeft: "1.5rem" }}
      >
        <ul>
          <li>
            <a className="external-link" href={Report_20_21} target="_blank">
              2020/2021 report
            </a>
          </li>
        </ul>
      </div>

      <br />
      <br />
      <Subscribe />
    </div>
  );
}
