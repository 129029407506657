import React from "react";
import PostCard from "../PostCard/";

import img1 from "../../assets/images/ecological.jpg";
import img2 from "../../assets/images/shutterstock-22.jpg";
import img3 from "../../assets/images/shutterstock-44.jpg";
import img4 from "../../assets/images/picture-88.jpg";
import img5 from "../../assets/images/shutterstock-55.jpg";

import "./PostSection.scss";

// Section on the homepage containing the 5 postcards

export default function PostSection() {
  return (
    <section className="post-section">
      <PostCard
        title="Skill Development Program"
        body="Sinai Foundation Canada is always looking for ways to help the community. The focus of this program is to provide needed help to newcomers to Canada, such as language skills, resume writing, interview skills and computer skills."
        img={img2}
        link="/our-work/projects/Skill-Development-Program"
      />
      <PostCard
        title="South Asian Community Wellness"
        body="Sinai Foundation Canada is well connected to the Peel community and it has created social engagement activities within the community for middle-aged to seniors who have the free time to contribute to the community while having fun building connections."
        img={img5}
        link="/our-work/projects/South-Asian-Community-Wellness-and-Engagement"
      />
      <PostCard
        title="Connecting Seniors, Enriching Communities"
        body="Starting in 2025, our new program will bring together seniors and youth to collaborate and bridge the generation gap, fostering mutual understanding and shared learning. Together, they will work towards promoting sustainability, combining the wisdom and experience of older generations with the energy and innovation of younger ones..."
        img={img3}
        link="/our-work/projects/Seniors-Wellbeing-Project-in-Mississauga"
      />
      {/* <PostCard
        title="Rapid Response"
        body="Sinai Foundation Canada always has been ready to contribute in emergency situations, such as COVID lockdown, the explosion in Beirut, or any emergency anywhere. We work with our partner organizations, UNHCR, Rotary, Red Cross, Lions Club and Inara to reach out to the communities where help is most needed."
              img={img4}
              link="our-work/projects/Community-Rapid-Response-Initiative"
      /> */}
      <PostCard
        title="Ecological Grief Program"
        body="Explore the Sinai Foundation Canada's Ecological Grief Program, launched during the COVID-19 lockdown. 
Through online music therapy, yoga, and virtual nature activities, the program helped manage eco-anxiety. 
In partnership with Red Cross Canada, it also provided essential grocery deliveries to vulnerable groups."
        img={img1}
        link="/our-work/projects/Ecological-Grief-Program"
      />
    </section>
  );
}
